import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.initializeSelect();
  }

  initializeSelect() {
    document.querySelectorAll('input.search-select').forEach((el)=>{
      if( el.tomselect ){
        console.log('Tom Select already initialized on this element');
        return;
      } else {
        let isMultiple = this.element.getAttribute("data-multiple") === "true";
        let settings = {
          searchField: ['value', 'text'],
          maxItems: isMultiple ? null : 1,
          closeAfterSelect: true,
          options: JSON.parse(this.element.getAttribute("data-options")),
          sortField: {
            field: "text",
            direction: "asc"
          },
          render:{
            no_results: function( data, escape ){
              return '<div class="no-results">Sin resultados</div>';
            },
          }
        };
        new TomSelect(el,settings);
      };
    });
  }
}
