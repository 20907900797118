import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nested-loan"
export default class extends Controller {
  static targets = ["template", "deleteButton"];
  static values = { index: Number };

  connect() {
    this.indexValue = this.templateTargets.length - 1;

    // from the second element, show the delete button
    this.deleteButtonTargets.slice(1).forEach((button) => {
      button.classList.remove("hidden");
    });
  }

  addElement() {
    const last = this.templateTargets[this.templateTargets.length - 1];
    const options = JSON.parse(last.getAttribute("data-options"));
    const new_el_html = `
      <div class="grid grid-cols-7 gap-x-4 gap-y-1" data-nested-loan-target="template" data-controller="search-select" data-options='${JSON.stringify(options)}'>
        <div class="col-span-3 equip">
          <label class="text-xs mb-2">Equipo</label>
          <input class="col-span-3 search-select" type="text" name="safety_equipment_loan[equipments_loans_attributes][99][safety_equipment_name]" id="safety_equipment_loan_equipments_loans_attributes_99_safety_equipment_name" tabindex="-1">
        </div>
        <div class="col-span-2">
          <label class="text-xs mb-2" for="safety_equipment_loan_equipments_loans_attributes_99_loan_date">Fecha de entrega</label>
          <input class="input-style" type="date" name="safety_equipment_loan[equipments_loans_attributes][99][loan_date]" id="safety_equipment_loan_equipments_loans_attributes_99_loan_date">
        </div>
        <div class="col-span-2">
          <label class="text-xs" for="safety_equipment_loan_equipments_loans_attributes_99_comment">Comentario</label>
          <input class="input-style" type="text" name="safety_equipment_loan[equipments_loans_attributes][99][comment]" id="safety_equipment_loan_equipments_loans_attributes_99_comment">
        </div>
      </div>`;
  
    console.log('añadiendo');
    const tempElement = document.createElement('div'); // Crear un elemento contenedor temporal
    tempElement.innerHTML = new_el_html.trim(); // Insertar el HTML
    const new_el = tempElement.firstChild; // Extraer el elemento creado
  
    this.indexValue += 1;
    // Actualizar los índices y IDs antes de insertar el elemento en el DOM
    new_el.querySelectorAll("input").forEach((input) => {
      input.value = "";
      input.name = input.name.replace(/\[\d+\]/, `[${this.indexValue}]`);
      input.id = input.id.replace(/\_\d+\_/, `_${this.indexValue}_`);
    });
  
    last.after(new_el); // Insertar el nuevo elemento en el DOM
  }
  

  removeElement(event) {
    const element = event.target.closest(
      "[data-nested-loan-target='template']"
    );
    element.classList.add("hidden");
    element.querySelector("input[name*='_destroy']").value = 1;
  }
}



