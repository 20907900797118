import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.initializeSelect();
  }

  initializeSelect() {
    var settings_name = {
      valueField: 'id',
      searchField: ['name', 'rut'],
      maxItems: 1,
      options: JSON.parse(this.element.getAttribute("data-workers")),
      closeAfterSelect: true,
      sortField: {
        field: "name",
        direction: "asc"
      },
      render: {
        option: function(data, escape) {
          return '<div>' +
              '<span class="name">' + escape(data.name) + '</span>' +
              '<span class="rut">' + escape(data.rut) + '</span>' +
            '</div>';
        },
        item: function(data, escape) {
          return '<span>' + escape(data.name) + '</span>';
        }
      }
    };
    
    new TomSelect('#safety_equipment_loan_worker_id',settings_name);
  }
}
